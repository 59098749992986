<template>
	<el-switch
		:active-value="activeValue"
		:inactive-value="inactiveValue"
		:width="generalMethods.isMobileScreen() ? 60 : 80"
		:active-text="localModelValue == inactiveValue && !changed ? generalMethods.translate(inactiveText) : generalMethods.translate(activeText)"
		:inactive-text="generalMethods.translate(inactiveText)"
		:custom-active-text="localModelValue == inactiveValue && !changed ? generalMethods.translate(inactiveText) : generalMethods.translate(activeText)"
		:custom-inactive-text="generalMethods.translate(inactiveText)"
		:inline-prompt="inlinePrompt"
		:class="sClass"
		v-bind="$attrs"
		v-model="localModelValue"
		@change="
			() => {
				changed = true;
			}
		"
	/>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "umt-element-plus-input",
	props: {
		activeValue: { default: 1 },
		inactiveValue: { default: 0 },
		activeText: { type: String, default: "active" },
		inactiveText: { type: String, default: "disabled" },
		inlinePrompt: { type: Boolean, default: true },
		sClass: { type: String, default: "el-switch-gray-off" },
		modelValue: {},
	},
	setup(props, { emit }) {
		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const changed = ref<boolean>(false);

		return {
			generalMethods,

			changed,

			localModelValue,
		};
	},
});
</script>
